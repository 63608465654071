import Form from '@/components/form';
import FormDatePicker from '@/components/form/fields/datePicker';
import FormTextField from '@/components/form/fields/textField';
import FormattedTextField from '@/components/formattedTextField';
import Loading from '@/components/loading';
import { useGraphQL } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import { CommerceRead, CommerceWrite } from '@/data/commerce/commerce.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import idPick from '@/helpers/idPick';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import SyncToClover from '@/helpers/syncToClover';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { Statuses } from '@/pages/dashboard/commerce/components/statuses';
import generatePaymentLink from '@/pages/dashboard/commerce/payments/form/utils';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { MutationCommerceWriteArgs, QueryCommerceReadArgs } from '@/types/schema';
import { Close as CloseIcon, Payment as PaymentIcon, HelpCenterRounded as HelpCenterRoundedIcon } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	Fade,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { addDays, format, isAfter, isBefore } from 'date-fns';
import { round, startCase, toLower } from 'lodash-es';
import { nanoid } from 'nanoid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function SchedulePaymentsModal( { id }: { id: string } ) {
	const { closeModal } = useModalControls();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const event = useEvents();
	const { t } = useTranslation();
	const { isCloverDevice } = useGetDeviceInfo();
	
	const { data, isLoading, isFetching } = useGraphQL<QueryCommerceReadArgs>( {
		query    : CommerceRead,
		queryKey : [ 'commerceRead' ],
		variables: { id },
	}, { placeholderData: undefined, enabled: Boolean( id ) } );
	
	const commerce = data?.commerceRead;
	
	const [ expanded, setExpanded ] = useState<boolean[]>( [] );
	
	if ( isLoading || isFetching ) return <Loading/>;
	
	if ( !commerce ) return null;
	
	return (
		<Form
			noClose
			enableReinitialize
			initialValues={{
				payments: commerce?.scheduledPayments?.map( ( payment ) => ( {
					amount      : round( commerce?.grandTotal * ( payment.percent || 0 ) / 100, 2 ),
					percent     : payment.percent,
					reason      : payment.reason,
					dueDate     : payment.dueDate,
					id          : payment.id,
					paid        : payment.paid,
					scheduleType: payment.scheduleType,
				} ) ) || [],
			}}
			onSubmit={async ( values ) => {
				if ( values.payments?.find( ( payment ) => payment.amount <= 0 || ( payment.percent || 0 ) <= 0 ) ) {
					enqueueSnackbar( t( 'common:no-zero-payment' ), { variant: 'default' } );
					return;
				}
				if ( values.payments?.find( ( payment ) => !payment.dueDate ) ) {
					enqueueSnackbar( t( 'common:payment-date-required' ), { variant: 'default' } );
					return;
				}
				const dates = values.payments.map( ( payment ) => payment.dueDate );
				
				let sortedDates = true;
				if ( dates.length === 1 ) {
					sortedDates = true;
				} else {
					for ( let i = 1; i < dates.length; i++ ) {
						if ( isBefore( dates[ i ], dates[ i - 1 ] ) ) {
							sortedDates = false;
						}
					}
				}
				
				if ( !sortedDates ) {
					enqueueSnackbar( t( 'common:date-ascending-order' ), { variant: 'default' } );
					return;
				}
				const lastDueDate = values.payments[ values.payments.length - 1 ]?.dueDate;
				await mutateGraphQL<MutationCommerceWriteArgs>( {
					mutation : CommerceWrite,
					variables: {
						id    : commerce?.id,
						method: 'Added Schedule Payments',
						input : {
							type             : commerce?.type,
							scheduledPayments: values.payments.map( ( payment ) => ( {
								...idPick( payment, [ 'reason', 'dueDate' ] ),
								percent: payment.percent,
								staff  : commerce?.staff?.id || staff?.id,
								client : commerce?.client?.id || null,
								company: commerce?.company.id,
							} ) ),
							dueDate: commerce?.dueDate ? isAfter( lastDueDate, commerce?.dueDate )
								? lastDueDate
								: commerce?.dueDate : lastDueDate,
						},
					},
				} );
				
				event.emit( 'reload.graphqlQuery', true );
				
				closeModal();
			}}>
			{( formik ) => {
				// Total paid schedule payments
				const formikPayments = formik.values?.payments;
				const paidSchedulePayments = formikPayments.filter( ( payment ) => payment.paid ).reduce( ( total, payment ) => total + Number( payment.amount ), 0 );
				
				// Total remaining after all paid schedule payments
				const remainingAfterAllPaid  = commerce?.grandTotal - paidSchedulePayments;
				
				// Total of all un paid schedule payments
				const totalUnpaidSchedulePayments = formikPayments.filter( ( payment ) => !payment.paid ).reduce( ( total, payment ) => total + Number( payment.amount ), 0 );
				
				const remainingUnpaidScheduledPaymentsAfterPaid = remainingAfterAllPaid - totalUnpaidSchedulePayments;
				const handlePaymentChange = ( index: number, type: 'amount' | 'percent', value: number ) => {
					const payments = [ ...formikPayments ];
					
					// Calculate the total of other unpaid payments (excluding the current one)
					const otherUnpaidPaymentsTotal = payments
						.filter( ( p, i ) => !p.paid && i !== index )
						.reduce( ( total, p ) => total + Number( p.amount ), 0 );
					
					// Calculate the maximum amount this payment can be
					const maxAmountForThisPayment = remainingAfterAllPaid - otherUnpaidPaymentsTotal;
					
					// Calculate the maximum percent this payment can be
					const maxPercentForThisPayment =  maxAmountForThisPayment / commerce?.grandTotal * 100;
					
					let finalAmount: number;
					let finalPercent: number;
					
					if ( type === 'amount' ) {
						// Ensure the amount doesn't exceed the maximum
						finalAmount = value > maxAmountForThisPayment
							? maxAmountForThisPayment
							: value;
						
						// Calculate the corresponding percent
						finalPercent = finalAmount / commerce?.grandTotal * 100;
					} else {
						// Only process if percent is within valid range
						if ( value > 100 ) return;
						
						// Ensure the percent doesn't exceed the maximum
						finalPercent = value > maxPercentForThisPayment
							? maxPercentForThisPayment
							: value;
						
						// Calculate the corresponding amount
						finalAmount = finalPercent / 100 * commerce?.grandTotal;
					}
					
					// Update the payment
					payments[ index ] = {
						...payments[ index ],
						amount : finalAmount,
						percent: finalPercent
					};
					
					formik.setFieldValue( 'payments', payments );
				};
				
				return (
					<ResponsiveModalContainer
						loading={formik.isSubmitting}
						closeOnSave={false}
						title={t( 'common:schedule-payment' )}
						secondaryTitle={`${t( 'common:schedule-payment-des-one' )} ${toLower( commerce?.type )}. ${t( 'common:schedule-payment-des-two' )}`}
						onSave={() => formik.handleSubmit()}>
						<Box sx={{ pb: 15 }}>
							<Stack
								direction='row'
								alignItems='center'
								justifyContent='space-between'
								sx={{ mb: 2 }}>
								<Typography variant='h1'>
									{currencyFormat( commerce?.grandTotal )} {!commerce?.number?.includes( 'Draft' ) && (
										<Typography color='text.secondary' component='span'>
											{`(#${commerce?.metadata?.customNumber || commerce?.number})`}
										</Typography>
									)}
								</Typography>
								<Button
									variant='text'
									color='primary'
									disabled={remainingUnpaidScheduledPaymentsAfterPaid <= 0}
									onClick={() => {
										const payments = [ ...formikPayments ];
										payments.push( {
											id     : nanoid(),
											amount : 0,
											percent: 0,
											dueDate: null,
											reason : '',
										} );
										formik.setFieldValue( 'payments', payments );
										setExpanded( () => {
											const newExpanded = new Array( payments.length ).fill( false );
											newExpanded[ newExpanded.length - 1 ] = true;
											return newExpanded;
										} );
									}}>
									{t( 'common:add-payment' )}
								</Button>
							</Stack>
							<Box>
								{formikPayments?.map( ( payment, index ) => (
									<Fade key={index} in timeout={500}>
										<Accordion
											sx={{
												bgcolor     : 'background.default',
												border      : 0,
												borderBottom: index === formikPayments.length - 1 ? 0 : 1,
												borderColor : 'divider',
											}}
											expanded={expanded[ index ] || false}
											onChange={( _: any, isExpanded: boolean ) => {
												setExpanded( ( prev ) => {
													const newExpanded = [ ...prev ];
													newExpanded[ index ] = isExpanded;
													return newExpanded;
												} );
											} }>
											<AccordionSummary>
												<Stack
													direction='row'
													alignItems='center'
													justifyContent='space-between'
													sx={{ width: '100%' }}
													px={.5}>
													<Stack>
														<Stack direction='row' spacing={1}>
															<Typography component='span'>
																{`Payment ${index + 1}: ${currencyFormat( payment?.amount )}`}
																<Typography color='text.secondary' component='span' mx={1}>
																	({round( payment?.percent || 0, 2 ) || ''}%)
																</Typography>
															</Typography>
														</Stack>
														<Typography color='text.secondary'>
															{payment?.dueDate && format( payment?.dueDate, 'PP' )}
														</Typography>
														{payment.paid && (
															<Typography sx={{ color: `${Statuses.PAID?.color}.main` }}>
																{Statuses.PAID?.label}
															</Typography>
														)}
														<Typography
															sx={{ mr: 3, WebkitLineClamp: 2, color: 'text.secondary' }}
															className='ellipsisText'>
															{payment?.reason}
														</Typography>
													</Stack>
													{!payment.paid && (
														<Box>
															<Tooltip title='Remove'>
																<IconButton
																	color='error'
																	onClick={() => {
																		const payments = [ ...formikPayments ];
																		payments.splice( index, 1 );
																		formik.setFieldValue( 'payments', payments );
																	} }>
																	<CloseIcon />
																</IconButton>
															</Tooltip>
														</Box>
													)}
												</Stack>
											</AccordionSummary>
											<AccordionDetails>
												<Stack spacing={2}>
													<Stack
														direction='row'
														alignItems='center'
														justifyContent='space-between'
														px={.5}>
														<Typography>
															{t( 'common:date-payment' )}
														</Typography>
														<Box>
															<FormDatePicker
																name={`payments[${index}].dueDate`}
																disabled={payment.paid}
																minDate={index === 0 || !formikPayments?.[ index - 1 ]?.dueDate
																	? new Date()
																	: addDays( formikPayments?.[ index - 1 ]?.dueDate, 1 )}
															/>
														</Box>
													</Stack>
													<Stack
														direction='row'
														alignItems='center'
														justifyContent='space-between'
														px={.5}>
														<Typography>
															{t( 'common:amount' )}
														</Typography>
														<Box>
															<FormattedTextField
																value={round( formikPayments[ index ].amount, 2 )}
																type='number'
																sx={{ width: 150 }}
																disabled={payment.paid}
																InputProps={{
																	startAdornment: <Typography color='text.secondary' pr={.5}>$</Typography>,
																	inputProps    : { min: 0, max: remainingAfterAllPaid },
																}}
																onFocus={( event ) => event.target.select()}
																onChange={( e ) => {
																	handlePaymentChange( index, 'amount', +e.target.value );
																}}
															/>
														</Box>
													</Stack>
													<Stack
														direction='row'
														alignItems='center'
														justifyContent='space-between'
														px={.5}>
														<Typography>
															{t( 'common:percent' )}
														</Typography>
														<Box>
															<FormattedTextField
																value={round( formikPayments[ index ].percent, 2 )}
																type='number'
																sx={{ width: 150 }}
																disabled={payment.paid}
																InputProps={{
																	startAdornment: <Typography color='text.secondary' pr={.5}>%</Typography>,
																	inputProps    : { min: 0, max: remainingUnpaidScheduledPaymentsAfterPaid / commerce?.grandTotal * 100 },
																}}
																onFocus={( event ) => event.target.select()}
																onChange={( e ) => handlePaymentChange( index, 'percent', +e.target.value )}
															/>
														</Box>
													</Stack>
													<FormTextField
														fullWidth
														multiline
														disabled={payment.paid}
														rows={3}
														label={t( 'common:reason' )}
														name={`payments[${index}].reason`}
														placeholder={t( 'common:reason-payment' )}
													/>
												</Stack>
												{!isCloverDevice && commerce?.client?.id && !payment.paid ? (
													<Box>
														<Tooltip
															placement='right'
															title='Share a secure payment link with your client that allows them to make this specific payment without seeing the full invoice details.'>
															<Button
																disabled={!payment.amount}
																startIcon={<PaymentIcon />}
																variant='text'
																size='small'
																color='primary'
																onClick={async () => {
																	if ( !commerce.companyLocation?.id ) {
																		enqueueSnackbar( 'No company location to create payment link.', { variant: 'error' } );
																		return;
																	}
																	const cloverLocation = commerce.companyLocation?.gateway?.external === 'CLOVER';

																	try {
																		const cloverOrder = cloverLocation && !commerce.externalId && await SyncToClover( commerce );
																		if ( cloverOrder?.id || cloverOrder?.commerce || commerce.externalId || !cloverLocation ) {
																			const link = generatePaymentLink( {
																				locationId        : commerce.companyLocation?.id,
																				amount            : payment.amount,
																				invoiceId         : commerce.id,
																				scheduledPaymentId: payment.id,
																				invoiceNumber     : commerce.metadata?.customNumber || commerce.number,
																				clientId          : commerce.client?.id,
																			} );
																			if ( link ) {
																				if ( !document.hasFocus() ) {
																					window.focus();
																					await new Promise( ( resolve ) => setTimeout( resolve, 300 ) ); // Give the browser some time to focus
																				}

																				if ( document.hasFocus() ) {
																					await navigator.clipboard.writeText( link );
																					enqueueSnackbar( t( 'common:link-copy-success' ), { variant: 'success' } );
																				}
																			}
																		}
																	} catch ( e ) {
																		enqueueSnackbar( 'Payment link could not be copied', { variant: 'error' } );
																	}
																} }>
																Copy Payment Link
															</Button>
														</Tooltip>
													</Box>
												) : null}
											</AccordionDetails>
										</Accordion>
									</Fade>
								) )}
							</Box>
						</Box>
						<Box
							sx={{
								p           : 2,
								bgcolor     : 'background.default',
								borderBottom: 1,
								borderColor : 'divider',
								position    : 'absolute',
								bottom      : 62,
								left        : 0,
								right       : 0,
								width       : '100%',
							}}>
							<Stack spacing={1} sx={{ p: 1, bgcolor: 'divider', borderRadius: 2 }}>
								{commerce?.dueDate && (
									<Stack direction='row' alignItems='center' justifyContent='space-between'>
										<Typography color='text.secondary'>
											{startCase( toLower( commerce.type ) )} Due Date:
										</Typography>
										<Typography>
											{safeFormatInTimeZone( commerce.dueDate, 'PP' )}
										</Typography>
									</Stack>
								)}
								<Divider/>
								<Stack direction='row' alignItems='center' justifyContent='space-between'>
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography color='text.secondary'>
											Remaining After Scheduled:
										</Typography>
										<Tooltip title='Amount still unscheduled after all planned payments. If negative, you have scheduled more than the invoice total.'>
											<HelpCenterRoundedIcon
												sx={{
													fontSize    : 16,
													borderRadius: 1,
													color       : 'text.secondary',
												}}
											/>
										</Tooltip>
									</Stack>
									<Typography variant='h4' fontWeight='500'>
										{currencyFormat( remainingUnpaidScheduledPaymentsAfterPaid )}
									</Typography>
								</Stack>
								<Divider/>
								<Stack direction='row' alignItems='center' justifyContent='space-between'>
									<Stack direction='row' alignItems='center' spacing={1}>
										<Typography color='text.secondary'>
											Remaining After Paid:
										</Typography>
										<Tooltip title='Amount still to be collected after considering only payments that have already been paid. This is the actual outstanding balance.'>
											<HelpCenterRoundedIcon
												sx={{
													fontSize    : 16,
													borderRadius: 1,
													color       : 'text.secondary',
												}}
											/>
										</Tooltip>
									</Stack>
									<Typography variant='h4' fontWeight='500'>
										{currencyFormat( commerce?.grandTotal - commerce?.paidTotal )}
									</Typography>
								</Stack>
							</Stack>
						</Box>
					</ResponsiveModalContainer>
				);
			}}
		</Form>
	);
}
