import type { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { InvoiceOrderEstimateEmailModal } from '@/modals/email/invoiceOrderEstimateEmailModal';
import useWriteLog from '@/pages/dashboard/commerce/components/utils';
import {
	checkViewAllPermission,
} from '@/pages/dashboard/management/teams/permissionsUtil';
import DeviceLoadingModal from '@/pages/dashboard/settings/devices/deviceLoadingModal';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Order } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { urlSearchParams } from '@/utils/urlSearchParams';
import {
	Edit as EditIcon,
	Email as EmailIcon,
	Payment as PaymentIcon,
	PictureAsPdf as PictureAsPdfIcon,
	Print as PrintIcon,
} from '@mui/icons-material';
import { isEmpty, toLower, upperFirst } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { stayOpen } from '../../components/tableHelpers';
import PaymentDrawer from '../../payment/paymentDrawer';

export function usePartialOrderTableActions() {
	const { t } = useTranslation();
	const { user } = useUserInfo();
	const { showModal } = useModal();
	const { isCloverDevice } = useGetDeviceInfo();
	const editable = usePermissions( permissions.orders.write );
	const payable = usePermissions( permissions.payments.write );
	const logWrite = useWriteLog();
	
	return ( order: Order, closeModal?: () => void ) => {
		const editPaidInvoicesAndOrders = order.status === 'PAID' && checkViewAllPermission( 'editPaidCommerces' );
		const type = upperFirst( toLower( order.type ) );
		
		return [
			editable && {
				name       : t( 'common:edit' ),
				icon       : <EditIcon/>,
				details    : !editPaidInvoicesAndOrders ? 'You do not have permission to edit this paid order.' : '',
				buttonProps: { disabled: !editPaidInvoicesAndOrders },
				onClick    : () => closeModal?.(),
				props      : {
					component: PageLinkComponent,
					href     : `/dashboard/commerce/orders/${order.id}/edit`,
				},
			}, payable
			&& ![ 'CANCELLED', 'PAID', 'PENDING' ].includes( order.status )
			&& !order.standing
			&& order.grandTotal > 0
			&& {
				name   : t( 'common:pay' ),
				icon   : <PaymentIcon/>,
				onClick: () => {
					closeModal?.();
					showModal( PaymentDrawer, {
						variant: 'drawer',
						onClose: ( event, reason ) => stayOpen( event, reason ),
					}, { order: order } );
				},
			}, payable && isCloverDevice
			&& order.gateway?.external === 'CLOVER'
			&& order.status !== 'PAID'
			&& order.status !== 'ACCOUNT'
			&& {
				name   : t( 'commerce:swipe-card' ),
				icon   : <PaymentIcon/>,
				onClick: async () => {
					showModal( DeviceLoadingModal, {
						maxWidth: 'sm',
						onClose : ( event, reason ) => stayOpen( event, reason ),
					}, {
						invoice       : order,
						title         : 'Swipe Card',
						bodyTitle     : 'Payment in progress.',
						buttonText    : 'Pay',
						secondaryTitle: `Pay this ${type} by card on Clover`,
					} );
					await logWrite( {
						company   : order.company.id,
						name      : 'UPDATE',
						method    : 'Swiped card on Clover',
						documentId: order.id,
						user      : user?.id,
						table     : 'ORDER',
					} );
				},
			}, !order.standing && {
				name   : isEmpty( order.metadata?.sentDates ) ? t( 'common:email' ) : t( 'common:remail' ),
				icon   : <EmailIcon/>,
				onClick: () => showModal( InvoiceOrderEstimateEmailModal, {
					onClose: ( event, reason ) => stayOpen( event, reason ),
				}, { id: order.id } ),
			}, !isCloverDevice && {
				name   : t( 'common:print' ),
				icon   : <PrintIcon/>,
				onClick: async () => {
					window.open( `/api/preview/print/${order.id}/invoice?timezone=${getBrowserTimezone()}`, '_blank' );
					
					await logWrite( {
						company   : order.company.id,
						name      : 'Print',
						method    : `Printed ${type}`,
						documentId: order.id,
						user      : user?.id,
						table     : 'ORDER',
					} );
					
				},
			}, isCloverDevice && order.externalId && order.gateway?.external === 'CLOVER' && {
				name   : t( 'common:print' ),
				icon   : <PrintIcon/>,
				onClick: async () => {
					if ( window?.Android ) {
						window.Android.onPrintReceiptByOrderId( order.externalId );
					}
					await logWrite( {
						company   : order.company.id,
						name      : 'Print',
						method    : `Printed ${type} on Clover`,
						documentId: order.id,
						user      : user?.id,
						table     : 'ORDER',
					} );
				},
			}, !isCloverDevice && {
				name   : t( 'common:pdf' ),
				icon   : <PictureAsPdfIcon/>,
				onClick: async () => {
					window.open( `/api/preview/pdf/${order.id}/order?${urlSearchParams( { timezone: getBrowserTimezone() } )}`, '_blank' );
					
					await logWrite( {
						company   : order.company.id,
						name      : 'PDF',
						method    : `PDF ${type}`,
						documentId: order.id,
						user      : user?.id,
						table     : 'ORDER',
					} );
					
				},
			}, !isCloverDevice && {
				name   : t( 'common:pdf-no-price' ),
				icon   : <PictureAsPdfIcon/>,
				onClick: async () => {
					window.open( `/api/preview/pdf/${order.id}/order?${urlSearchParams( {
						s       : 'pickup',
						timezone: getBrowserTimezone(),
					} )}`, '_blank' );
					
					await logWrite( {
						company   : order.company.id,
						name      : 'PDF',
						method    : `PDF No Price ${type}`,
						documentId: order.id,
						user      : user?.id,
						table     : 'ORDER',
					} );
					
				},
			},
		] as ActionProps[];
	};
}
