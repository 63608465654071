import { useGraphQL } from '@/data';
import { GatewaysRead } from '@/data/gateway.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Order, QueryGatewaysReadArgs } from '@/types/schema';
import { Box, ListItem, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { isEmpty, isString, uniq } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type QBTaxType = {
	id: string,
	name: string,
	prices: any,
	value: number
};

export function QBTaxesButton( {
	tax,
	setTax,
	showListItem,
}: {
	tax: QBTaxType | null,
	setTax: ( tax: QBTaxType | null ) => void,
	showListItem?: boolean
} ) {
	
	const { data } = useGraphQL<QueryGatewaysReadArgs>( {
		query    : GatewaysRead,
		queryKey : [ 'gatewaysRead' ],
		variables: { options: { filter: { external: 'QUICKBOOKS' } } },
	} );
	
	const gateway = data?.gatewaysRead?.items?.[ 0 ];
	
	const { data: taxCodes, isLoading } = useQuery(
		[ 'taxRates', gateway?.id ],
		() => axios.post( `/api/quickbooks/${gateway?.id}/getTaxRates` ), {
			enabled: !!gateway,
			select : ( { data } ) => [
				{ id: null, name: 'No Tax', prices: [], value: 0 },
				...data.taxCode?.filter( ( tc ) => !isNaN( tc.Id ) ).map( ( tc ) => ( {
					id    : tc.Id,
					name  : tc.Name,
					prices: tc?.SalesTaxRateList?.TaxRateDetail?.reduce( ( pricesArr, trd ) => {
						const taxRate = data.taxRate?.find( ( tr ) => tr.Id === trd.TaxRateRef.value );
						if ( taxRate ) {
							pricesArr = [
								...pricesArr, {
									id       : taxRate.Id,
									value    : taxRate.RateValue,
									isPercent: true,
									quantity : 1,
									name     : taxRate.Name,
									metadata : { taxCodeRef: tc.Id },
								} ];
						}
						return pricesArr;
					}, [] ),
					value: tc?.SalesTaxRateList?.TaxRateDetail?.reduce( ( sumTax, trd ) => {
						sumTax = sumTax + data.taxRate?.find( ( tr ) => tr.Id === trd.TaxRateRef.value )?.RateValue;
						return sumTax;
					}, 0 ),
				} ) ) ],
		} );
	
	if ( isEmpty( gateway ) ) return null;
	
	const select = (
		<Select
			displayEmpty
			sx={{ minWidth: 180 }}
			value={tax?.id || 'noTax'}
			disabled={isLoading || taxCodes?.length < 1}
			renderValue={( selected ) => {
				if ( selected === 'noTax' ) return 'No Tax';
				const selectedTax = taxCodes?.find( ( item ) => item.id === selected );
				return selectedTax ? `${selectedTax.name} (${selectedTax.value}%)` : 'No Tax';
			}}>
			{isLoading ? (
				<MenuItem disabled>Loading...</MenuItem>
			) : uniq( taxCodes || [] ).map( ( item, index ) => (
				<MenuItem
					key={index}
					value={item.id || 'noTax'}
					onClick={() => {
						item.name === 'No Tax' ? setTax( null ) : setTax( item );
					}}>
					{`${item.name} ${item.name !== 'No Tax' ? `(${item.value}%)` : ''}`}
				</MenuItem>
			) )}
		</Select>
	);
	if ( showListItem ) return (
		<ListItem sx={{ py: 1 }}>
			<ListItemText
				primary='Tax Rate'
				secondary='Select the QuickBooks tax rate to apply'
			/>
			{select}
		</ListItem>
	);
	return select;
}

export default function QbInvoiceTax( { invoice, companyId, syncPayments, noSync, getQBTax }: {
	invoice: Order,
	companyId: string,
	syncPayments: boolean,
	noSync?: boolean,
	getQBTax?: ( tax ) => void
} ) {
	const { staff } = useUserInfo();
	const [ tax, setTax ] = useState<QBTaxType | null>( null );
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	
	return (
		<ResponsiveModalContainer
			title={t( 'common:select-tax' )}
			saveButtonText={tax?.name === 'No Tax' || !tax
				? t( 'common:select-tax-btn-two' )
				: t( 'common:select-tax-btn-three' )}
			onSave={async () => {
				getQBTax?.( tax );
				if ( !noSync ) {
					try {
						enqueueSnackbar( t( 'common:qb-sync-inprogress' ), { variant: 'info' } );
						await axios.post( '/api/processor/manageQB/postInvoice', {
							id     : invoice.id,
							company: companyId,
							prices : tax?.prices,
							syncPayments,
							staffId: staff?.id,
						} );
						enqueueSnackbar( t( 'common:qb-sync-success' ), { variant: 'success' } );
					} catch ( e ) {
						const cloverErrors = e?.response.data?.cloverErrors;
						if ( cloverErrors ) {
							throw isString( cloverErrors )
								? cloverErrors
								: cloverErrors?.error?.message || cloverErrors?.message || t( 'common:qb-sync-error' );
						} else {
							enqueueSnackbar( t( 'common:qb-sync-conflict' ), { variant: 'default' } );
						}
						
					}
				}
			}}>
			<Box>
				<Typography>
					{t( 'common:multi-tax-des' )}
				</Typography>
				<QBTaxesButton tax={tax} setTax={setTax}/>
			</Box>
		</ResponsiveModalContainer>
	);
}
