import { getUserInfo } from '@/providers/auth/useUserInfo';
import { Permissions } from '@/types/schema';

type PermissionType =
	'commerces'
	| 'payments'
	| 'purchases'
	| 'statements'
	| 'clients'
	| 'editPaidCommerces'
	| 'itemCosts';

export const checkViewAllPermission = ( permissionType: PermissionType ) => {
	const { staff } = getUserInfo();
	const permissionMap: { [key in PermissionType]: Permissions } = {
		commerces        : 'ACCESS_OTHER_STAFF_COMMERCES',
		payments         : 'ACCESS_OTHER_STAFF_PAYMENTS',
		purchases        : 'ACCESS_OTHER_STAFF_PURCHASES',
		statements       : 'ACCESS_OTHER_STAFF_STATEMENTS',
		clients          : 'ACCESS_OTHER_STAFF_CLIENTS',
		editPaidCommerces: 'EDIT_PAID_INVOICES_AND_ORDERS',
		itemCosts        : 'VIEW_ITEM_COSTS',
	};
	
	return (
		staff?.permissions?.includes( 'OWNER' )
		|| staff?.permissions?.includes( 'ADMIN' )
		|| staff?.permissions?.includes( permissionMap[ permissionType ] as Permissions )
	);
};
